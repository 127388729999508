<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Objects" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  :class="{'is-invalid': isTabInvalid(title)}"
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  :messages="['The recommended number of characters is 100']"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <span style="color: red;" v-if="errors.region_id">{{ errors.region_id[0] }}</span>
                <va-select
                  label="Регіон *"
                  v-model="region"
                  :options="regions"
                  textBy="label"
                  :error="!!errors.region_id"
                  @input="delete errors.region_id"
                  no-clear
                />
                <va-toggle label="Published" small v-model="published"/>
                <va-input
                  label="Position"
                  v-model.number="position"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-card class="mb-3" title="Before Image *">
                  <div v-if="beforeImageExisting">
                    <a style="display: block;" target="_blank" :href="beforeImageExisting"><img style="max-width: 400px;" :src="beforeImageExisting"></a>
                    <div><va-button small color="danger" @click="beforeImageExisting = deleteImage(beforeImageId) ? '' : beforeImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    @input="delete errors.beforeImage"
                    dropzone
                    v-model="beforeImage"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.beforeImage" style="color: red;">{{ this.errors.beforeImage[0] }}</div>
                  <va-button @click="uploadImage('beforeImage', 'ObjectItemBeforeImage', 'ObjectItem[beforeImage]', beforeImage[0])">Завантажити</va-button>
                </va-card>
                <va-card class="mb-3" title="After Image *">
                  <div v-if="afterImageExisting">
                    <a style="display: block;" target="_blank" :href="afterImageExisting"><img style="max-width: 400px;" :src="afterImageExisting"></a>
                    <div><va-button small color="danger" @click="afterImageExisting = deleteImage(afterImageId) ? '' : afterImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.afterImage"
                    v-model="afterImage"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.afterImage" style="color: red;">{{ this.errors.afterImage[0] }}</div>
                  <va-button @click="uploadImage('afterImage', 'ObjectItemAfterImage', 'ObjectItem[afterImage]', afterImage[0])">Завантажити</va-button>
                </va-card>
                <va-card class="mb-3" title="File">
                  <div v-if="fileExisting">
                    <a class="link" style="display: block;" target="_blank" :href="fileExisting">{{ fileExisting }}</a>
                    <div><va-button small color="danger" @click="fileExisting = deleteImage(fileId) ? '' : fileExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити новий файл</span>
                  <va-file-upload
                    dropzone
                    v-model="file"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('file', 'ObjectItemFile', 'ObjectItem[file]', file[0])">Завантажити</va-button>
                </va-card>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <span style="color: red;" v-if="errors.month_start">{{ errors.month_start[0] }}</span>
                <va-select
                  label="Month start *"
                  v-model="monthStart"
                  :options="months"
                  :error="!!errors.month_start"
                  @input="delete errors.month_start"
                  no-clear
                />
                <span style="color: red;" v-if="errors.year_start">{{ errors.year_start[0] }}</span>
                <va-select
                  label="Year start *"
                  v-model="yearStart"
                  :options="years"
                  :error="!!errors.year_start"
                  @input="delete errors.year_start"
                  no-clear
                />
                <span style="color: red;" v-if="errors.month_finish">{{ errors.month_finish[0] }}</span>
                <va-select
                  label="Month finish *"
                  v-model="monthFinish"
                  :options="months"
                  :error="!!errors.month_finish"
                  @input="delete errors.month_finish"
                  no-cleaar
                />
                <span style="color: red;" v-if="errors.year_finish">{{ errors.year_finish[0] }}</span>
                <va-select
                  label="Year finish *"
                  v-model="yearFinish"
                  :options="years"
                  :error="!!errors.year_finish"
                  @input="delete errors.year_finish"
                  no-clear
                />
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <span style="color: red;" v-if="errors.type_id">{{ errors.type_id[0] }}</span>
                <va-select
                  label="Type *"
                  v-model="type"
                  :options="types"
                  textBy="label"
                  :error="!!errors.type_id"
                  @input="delete errors.type_id"
                  no-clear
                />
                <span style="color: red;" v-if="errors.class_id">{{ errors.class_id[0] }}</span>
                <va-select
                  label="Class *"
                  v-model="selectedClass"
                  :options="classes"
                  textBy="label"
                  :error="!!errors.class_id"
                  @input="delete errors.class_id"
                  no-clear
                />
                <va-input
                  label="Length *"
                  v-model.number="llength"
                  @input="delete errors.llength"
                  :error="!!errors.llength"
                  :error-messages="errors.llength"
                />
                <span style="color: red;" v-if="errors.additional_work">{{ errors.additional_work[0] }}</span>
                <va-select
                  label="Additional Work *"
                  v-model="selectedAw"
                  multiple
                  :options="aws"
                  textBy="label"
                  :error="!!errors.additional_work"
                  @input="delete errors.additional_work"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      loaded: false,

      label: '',
      position: '',
      region: '',
      regions: [],
      published: false,

      beforeImage: [],
      beforeImageId: '',
      beforeImageExisting: '',
      afterImage: [],
      afterImageId: '',
      afterImageExisting: '',
      file: [],
      fileId: '',
      fileExisting: '',

      months: [],
      years: [],
      monthStart: '',
      yearStart: '',
      monthFinish: '',
      yearFinish: '',

      type: '',
      types: [],
      selectedClass: '',
      classes: [],
      llength: '',
      selectedAw: [],
      aws: [],

      sign: '',

      errors: [],

      tabTitles: ['Main', 'Image&Files', 'Time', 'Info'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (attr, entityAttribute, param, file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', 'backend\\modules\\object\\models\\ObjectItem')
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (this.$attrs.id) {
        formData.append('id', this.$attrs.id)
      } else {
        formData.append('sign', this.sign)
      }

      axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    isTabInvalid (name) {
      const tab = this.tabTitles.findIndex(item => item === name)

      if (tab === 0) {
        return !!this.errors.label || !!this.errors.region_id || !!this.errors.position
      } else if (tab === 1) {
        return !!this.errors.beforeImage || !!this.errors.afterImage
      } else if (tab === 2) {
        return !!this.errors.month_start || !!this.errors.year_start || !!this.errors.month_finish || !!this.errors.year_finish
      } else if (tab === 3) {
        return !!this.errors.type_id || !!this.errors.class_id || !!this.errors.llength || !!this.errors.additional_work
      }
      return false
    },
    submit () {
      const data = {
        label: this.label,
        position: this.position,
        published: !!this.published,
        region_id: this.region.id,
        month_start: this.monthStart.id,
        month_finish: this.monthFinish.id,
        year_start: this.yearStart.id,
        year_finish: this.yearFinish.id,
        type_id: this.type.id,
        class_id: this.selectedClass.id,
        length: this.llength,
        additional_work: this.selectedAw.length ? this.selectedAw.map(item => item.id.toString()) : null,
        sign: this.sign ? this.sign : null,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/object-item/${this.$attrs.id}`, data)
          .then(response => {
            this.$router.push({ name: 'autostrada-widgets-objects' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
              if (Object.keys(this.errors).includes('length')) {
                this.errors.llength = Object.entries(this.errors).filter(item => item[0] === 'length')[0][1]
              }
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/object-item`, data)
          .then(response => {
            this.$router.push({ name: 'autostrada-widgets-objects' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
              if (Object.keys(this.errors).includes('length')) {
                this.errors.llength = Object.entries(this.errors).filter(item => item[0] === 'length')[0][1]
              }
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/object-item${this.$attrs.id ? '/' + this.$attrs.id : ''}`)
        .then(response => {
          this.regions = response.data.form.regions
          this.years = response.data.form.years
          this.types = response.data.form.type
          this.classes = response.data.form.class
          this.aws = response.data.form.aw
          this.months = response.data.form.months

          if (this.$attrs.id) { // update
            this.label = response.data.object.label
            this.position = response.data.object.position
            this.published = !!response.data.object.published
            this.llength = response.data.object.length

            this.region = this.regions.find(item => item.id === response.data.object.region_id)

            this.yearStart = this.years.find(item => item.id === response.data.object.year_start)
            this.yearFinish = this.years.find(item => item.id === response.data.object.year_finish)

            this.monthStart = this.months.find(item => item.id === response.data.object.month_start)
            this.monthFinish = this.months.find(item => item.id === response.data.object.month_finish)

            this.type = this.types.find(item => item.id === response.data.object.type_id)

            this.selectedClass = this.classes.find(item => item.id === response.data.object.class_id)

            this.selectedAw = this.aws.filter(item => response.data.object.aw.includes(item.id.toString()))

            this.beforeImageExisting = process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.object.before_image.originalSrc
            this.beforeImageId = response.data.object.before_image_id
            this.afterImageExisting = process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.object.after_image.originalSrc
            this.afterImageId = response.data.object.after_image_id

            this.fileExisting = response.data.object.file ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.object.file : null
            this.fileId = response.data.object.file_id
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

.va-tab {
  &.is-invalid {
    opacity: 1 !important;
    color: red !important;
  }
}
</style>
